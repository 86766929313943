












































.title-placeholder {
  font-size: 0.8rem;
  font-style: italic;
  line-height: 1em;
  color: darken(white, 25%);
  cursor: pointer;
}
